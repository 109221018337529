/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 返信の有無
 */
export type ReviewHasReply = typeof ReviewHasReply[keyof typeof ReviewHasReply];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviewHasReply = {
  WITH_REPLY: 'WITH_REPLY',
  NO_REPLY: 'NO_REPLY',
} as const;
