import { Icon } from '@/components/Icon';
import { Select } from '@/components/Select';
import { Link } from '@chakra-ui/next-js';
import {
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';

type PostStoreGroupDialogProps = {
  initIds?: string[];
  isReadonly?: boolean;
  onSubmit: (storeIds: string[]) => void;
};

export function PostStoreGroupDialog({
  initIds,
  isReadonly = false,
  onSubmit,
}: PostStoreGroupDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabState, setTabState] = useState<'store' | 'group'>('store');
  const [isNoGroup, setIsNoGroup] = useState<boolean>(false);
  const [storeWord, setStoreWord] = useState<string>('');
  const [groupWord, setGroupWord] = useState<string>('');
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [storeIds, setStoreIds] = useState<string[]>(initIds ? initIds : []);

  const handleClose = useCallback(() => {
    setIsNoGroup(false);
    setStoreWord('');
    setGroupWord('');
    setSelectIds(storeIds);
    onClose();
  }, [onClose, storeIds]);

  const handleSubmit = useCallback(() => {
    setStoreIds(selectIds);
    onSubmit(selectIds);
    onClose();
  }, [onClose, onSubmit, selectIds]);

  return (
    <>
      <Flex gap={4} alignItems="center">
        <Button
          variant={storeIds.length > 0 && !isReadonly ? 'tertiary' : 'negative'}
          fontWeight="normal"
          onClick={onOpen}
          isDisabled={isReadonly}
        >
          店舗/グループ選択
        </Button>
        <Text>
          {storeIds.length > 0
            ? `${storeIds.length}店舗選択中`
            : '会社を選択してください'}
        </Text>
      </Flex>

      <Modal
        variant="fixedHeight"
        size="lg"
        isOpen={isOpen}
        onClose={handleClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>投稿する店舗/グループを選択</ModalHeader>
          <ModalCloseButton />
          <ModalBody py={0}>
            <Tabs
              w="full"
              onChange={(id) => {
                if (id === 0) setTabState('store');
                if (id === 1) setTabState('group');
              }}
            >
              <TabList>
                <Tab>
                  <Flex alignItems="center">店舗</Flex>
                </Tab>
                <Tab>
                  <Flex alignItems="center">グループ</Flex>
                </Tab>
              </TabList>
              <TabIndicator />
              <TabPanels>
                <TabPanel pb={0}>
                  <Stack gap={6}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Icon name="search" color="blue.500" />
                      </InputLeftElement>
                      <Input
                        placeholder="店舗名を検索"
                        pl={10}
                        onChange={(e) => setStoreWord(e.target.value)}
                      />
                    </InputGroup>
                    {storeWord ? (
                      <Center textAlign="center" mt={8}>
                        <Stack align="center" gap={6}>
                          <Icon name="noResults" color="gray.200" size="xl" />
                          <Stack gap={3}>
                            <Text size="md" color="gray.500">
                              該当する店舗が見つかりませんでした
                            </Text>
                            <Text size="sm" color="gray.400">
                              キーワードを変更してみたり、
                              <br />
                              入力ミスがないかをご確認ください。
                            </Text>
                          </Stack>
                        </Stack>
                      </Center>
                    ) : (
                      <Stack overflowY="auto" maxH="240px">
                        <CheckboxGroup
                          value={selectIds}
                          onChange={(values) =>
                            setSelectIds(values.map((val) => String(val)))
                          }
                        >
                          <Checkbox value={'QnVkyM1QM9r1'}>
                            呑み処じゃいご
                          </Checkbox>
                          <Checkbox value={'pPjvyYElgN4w'}>
                            software And Design
                          </Checkbox>
                        </CheckboxGroup>
                      </Stack>
                    )}
                  </Stack>
                </TabPanel>
                <TabPanel pb={0}>
                  <Stack gap={6}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Icon name="search" color="blue.500" />
                      </InputLeftElement>
                      <Input
                        placeholder="グループ名を検索"
                        pl={10}
                        onChange={(e) => setGroupWord(e.target.value)}
                      />
                    </InputGroup>
                    {isNoGroup ? (
                      <Center flexDirection="column" mt={16}>
                        <Text>まだグループがありません</Text>
                        <Link href={``}>グループを作成</Link>
                      </Center>
                    ) : (
                      <>
                        {groupWord ? (
                          <Center textAlign="center" mt={8}>
                            <Stack align="center" gap={6}>
                              <Icon
                                name="noResults"
                                color="gray.200"
                                size="xl"
                              />
                              <Stack gap={3}>
                                <Text size="md" color="gray.500">
                                  該当するグループが見つかりませんでした
                                </Text>
                                <Text size="sm" color="gray.400">
                                  キーワードを変更してみたり、
                                  <br />
                                  入力ミスがないかをご確認ください。
                                </Text>
                              </Stack>
                            </Stack>
                          </Center>
                        ) : (
                          <Stack overflowY="auto" maxH="240px">
                            <CheckboxGroup onChange={() => setIsNoGroup(true)}>
                              <Checkbox value={'1'}>関東</Checkbox>
                              <Checkbox value={'2'}>デリバリー</Checkbox>
                            </CheckboxGroup>
                          </Stack>
                        )}
                      </>
                    )}
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            {tabState === 'store' && selectIds.length > 0 && (
              <Text fontWeight="bold" color="black" mr="auto">
                {selectIds.length} 店舗を選択しています
              </Text>
            )}
            {tabState === 'group' && (
              <Flex fontSize="md" alignItems="center" gap={2} mr="auto">
                選択したグループの
                <Select
                  size="md"
                  options={[
                    {
                      value: 'and',
                      label: 'すべて',
                    },
                    {
                      value: 'or',
                      label: 'いずれか',
                    },
                  ]}
                  defaultValue={{
                    value: 'and',
                    label: 'すべて',
                  }}
                  onChange={() => {}}
                />
                に該当する店舗に投稿
              </Flex>
            )}
            <Button variant="secondary" onClick={handleClose}>
              キャンセル
            </Button>
            <Button onClick={handleSubmit}>決定</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
