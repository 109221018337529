export const helpText = {
  base: {
    name: {
      title: '店舗名',
      text: [
        '店舗名を入力しましょう。（必須入力）',
        '設定した内容は以下の媒体に連携されます。',
        '・・',
        'メモ：GBPで「店舗名にプロモーション等を含めるとGBPのアカウントがBanされます」みたいなやつも足しておきたい。',
      ],
    },
    brand: {
      title: 'ブランド',
      text: [
        'ブランド名を選択しましょう。（必須入力）',
        '設定した内容は以下の媒体に連携されます。',
        'メモ：Apple mapの店舗名にはブランド名が連携されます。',
        'ブランドの登録はこちら',
      ],
    },
    group: {
      title: 'グループ',
      text: [
        'この店舗を管理するためのグループを任意で設定することができます。',
        'グループは複数選択することが可能です。',
        'グループの登録はこちら',
      ],
    },
    address: {
      title: '住所',
      text: [
        '国または地域',
        '必須入力になります。',
        '適切な値を選択してください。',
        '　',
        '郵便番号',
        '郵便番号を入力してください。',
        '・日本の店舗：000-0000のように-込みで入力してください。',
        '・海外の店舗：0000000のように数字のみ入力してください。',
        '　',
        '都道府県または州',
        '必須入力になります。',
        '適切な値を選択してください。',
        '　',
        '市区町村',
        '必須入力になります。',
        '適切な値を入力してください。',
        '　',
        '町名または地名',
        '必須入力になります。',
        '適切な値を入力してください。',
        '　',
        '番地',
        '必須入力になります。',
        '適切な値を入力してください。',
        '　',
        '建物名',
        '任意で入力してください。',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        '店舗にアクセスする際の電話番号を設定することができます。',
        '店舗マスタに設定された電話番号は以下の媒体に自動で連携されます。',
        'GBP、Apple map、Yahoo Place、Facebook',
        '※Facebookについては、店舗マスタの国名が「日本」の場合のみ連携されます。',
      ],
    },
    businessHours: {
      title: '営業時間',
      text: [
        '営業時間を任意で設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        'GBP、Apple map、Yahoo Place、Facebook',

        'メモ',
        '店舗マスタからFacebookに連携するには、Facebookのアカウントに住所が設定されている必要があります。',
        'Facebookの仕様上、カンリーからFacebookの住所は更新できないため、お手数ですが、Facebookのアカウント上で住所を直接入力してください。',
      ],
    },
    Irregular: {
      title: '特別営業時間',
      text: [''],
    },
    establish: {
      title: '開業日',
      text: [
        '店舗の開業日を任意で設定することができます。',
        '設定した内容は以下の媒体に連携されます。',
        '・・',
      ],
    },
    status: {
      title: '営業状況',
      text: [
        '店舗の開業、休業、閉業のステータスを任意で設定することができます。',
        '設定した内容は以下の媒体に連携されます。',
        'カンリーホームページ・・',

        'カンリーホームページを開設していない場合はカンリーホームページの表記は無くした方が良いかも',
      ],
    },
    snsLinks: {
      title: 'SNSリンク',
      text: [
        'SNSリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に連携されます。',
        'Googleビジネスプロフィール、Apple map・・・',
        '1店舗につき、以下の媒体のリンクが最大一つまで設定可能です。',
        'Facebook、Instagram、X',
      ],
    },
    code: {
      title: '店番',
      text: [
        '店舗を識別するためのユニークな番号を任意で設定することができます。',
        '40文字まで入力可能です。',
      ],
    },
  },
  google: {
    name: {
      title: 'ビジネス名',
      text: [
        'この店舗の正式名称を設定してください',
        '例）居酒屋カンリー 目黒店',
        '※キャッチコピーやタグは入力しないでください',
        '',
        '（店舗マスタの「店舗名」が反映されています）',
      ],
    },
    mainCategory: {
      title: 'メインカテゴリ',
      text: [
        'この店舗に該当するカテゴリ（業態）を1つ選択してください。',
        '',
        '例）居酒屋、カフェ、ヘアサロン',
        '',
        'なお、一部のカテゴリではGoogleの仕様により、カンリーから「サービス」の登録ができません。',
        '登録の際にエラーが発生した場合は、GBPの管理画面から登録をお願いいたします。',
        '',
        '「サービス」をカンリーから登録できないカテゴリの例（一部抜粋）：',
        '倉庫、セルフストレージ施設、建材店、金物店、作業服店、工具レンタル業、雑貨店、ギフト雑貨ショップ、化粧品、文房具、調剤薬局、スーパーマーケット',
      ],
    },
    additionalCategory: {
      title: '追加カテゴリ',
      text: ['（上記「メインカテゴリ」と同じ）'],
    },
    address: {
      title: '住所',
      text: [
        'この店舗の住所を設定してください',
        '（店舗マスタの「住所」が反映されています）',
      ],
    },
    area: {
      title: 'サービス提供地域',
      text: [
        'この店舗で商品配達や出張型サービスを提供している場合のみ、その対象地域を選択してください',
        '例）東京都、東京都目黒区、埼玉県さいたま市',
      ],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'この店舗の営業時間を設定してください',
        '（店舗マスタの「営業時間」が反映されています）',
      ],
    },
    hours: {
      title: '営業時間の詳細',
      text: [
        '宅配・テイクアウト・ドライブスルー・店舗受け取りなど、この店舗で提供している特定のサービスの営業時間を設定してください',
      ],
    },
    businessDates: {
      title: '特別営業時間',
      text: [
        'この店舗で、営業時間が通常とは異なる日がある場合は設定してください',
        '例）祝日、貸切がある日、定休日ではないが営業しない日',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        'この店舗の電話番号を設定してください',
        '（店舗マスタの「電話番号」が反映されています）',
      ],
    },
    website: {
      title: 'ウェブサイト',
      text: [
        'この店舗のウェブサイトURLを設定してください',
        '例）https://jp.can-ly.com/',
        '※誘導したいサイトのURLでも設定可能です',
      ],
    },
    snsLinks: {
      title: 'SNSリンク',
      text: [
        'この店舗のソーシャルメディアURLを設定してください',
        '（Instagram・Facebook・X(旧Twitter)のリンクは、店舗マスタの「ソーシャルメディアURL」が反映されています）',
      ],
    },
    bookings: {
      title: '予約',
      text: [
        'この店舗の予約ページURLを設定してください',
        '',
        '例）自社HP・お店独自で制作したホームページの予約リンク',
      ],
    },
    attributes: {
      title: '属性',
      text: [
        'この店舗の特長を設定してください',
        '例）テラス席やWi-Fi の有無、決済方法など',
      ],
    },
    businessInfo: {
      title: 'ビジネス情報',
      text: [
        'この店舗の特長や、この店舗で提供しているサービス・商品に関する情報を設定してください',
        '例）京王井の頭線渋谷駅から徒歩1分、山手線渋谷駅から徒歩3分にある駅近のイタリアンレストランです。こじんまりとした落ち着いた空間で、大切なパートナーやご友人との時間をゆったりと過ごすことができます。',
        'イタリア産のボトルワインやビール、お酒が飲めない方には見た目もおしゃれなノンアルコールカクテルなど、豊富に取り揃えております。お酒と一緒に楽しめるお食事メニューのご用意もございます。',
        'お家に帰る前に、気の合う仲間や気さくなスタッフと素敵な時間を過ごしませんか？',
        'お気軽にご予約ください！心よりご来店お待ちしております。',
      ],
    },
    establishmentDate: {
      title: '開業日',
      text: ['この店舗の開業日を設定してください'],
    },
    code: {
      title: '店舗コード',
      text: [
        'この店舗の「店舗コード」を設定してください',
        '（店舗アカウントマスタの「店番」が反映されています）',
      ],
    },
    status: {
      title: '営業状況',
      text: [
        'この店舗の営業状況を設定してください',
        '（店舗アカウントマスタの「休業設定」が反映されています）',
      ],
    },
    assetItems: {
      ADDITIONAL: {
        title: 'オーナー提供',
        text: ['Googleビジネスプロフィールのオーナーが追加した写真です。'],
      },
      CUSTOMERS: {
        title: 'ユーザー提供',
        text: [
          '実際に店舗を訪れたお客様が、クチコミなどでアップロードした写真です。',
        ],
      },
      COVER: {
        title: 'カバー',
        text: [
          '店舗のカバー写真です。店舗をもっともよく表す写真を1枚設定してください。',
          'Googleの仕様により、設定した写真が Google 上で最初に表示されない場合があります。',
        ],
      },
      PROFILE: {
        title: 'プロフィール',
        text: ['', ''],
      },
      LOGO: {
        title: 'ロゴ',
        text: [
          '店舗のロゴ写真です。',
          'お客様が店舗を見つけやすいように、ロゴ写真を1枚設定してください。',
        ],
      },
      EXTERIOR: {
        title: '外見',
        text: [
          '店舗の外観写真です。',
          'お客様が店舗を見つけやすいように、店舗の入口や周辺環境等の外観写真を設定してください。',
        ],
      },
      INTERIOR: {
        title: '店内',
        text: [
          '店舗の店内写真です。',
          '店舗の雰囲気がお客様に伝わるように、内装などの店内写真を設定してください。',
        ],
      },
      FOOD_AND_DRINK: {
        title: '食品と飲料',
        text: [
          '店舗の食べ物や飲み物の写真です。',
          'お客様が食事のプランを考えやすいように、食べ物や飲み物の写真を設定してください。',
        ],
      },
      PRODUCT: {
        title: '商品',
        text: [
          '店舗の商品写真です。',
          '扱っている商品がお客様に伝わるように、代表的な商品や人気の高い商品の写真を設定してください。',
        ],
      },
      MENU: {
        title: 'メニュー',
        text: [
          '店舗のメニュー写真です。',
          '提供しているメニューがお客様に伝わるように、代表的なメニューや人気の高いメニューの写真を設定してください。',
          'メニュー情報と一緒にアップロードした写真もこの項目に表示されます。',
        ],
      },
      TEAMS: {
        title: 'チーム',
        text: [
          '店舗スタッフの写真です。',
          'お客様にスタッフの人となりを感じてもらえるように、店舗スタッフの写真を設定してください。',
        ],
      },
      AT_WORK: {
        title: '職場',
        text: [
          '職場環境の写真です。',
          'お客様に職場の雰囲気が伝わるように、職場やオフィスの写真を設定してください。',
        ],
      },
      COMMON_AREA: {
        title: '共用エリア',
        text: ['', ''],
      },
      ROOMS: {
        title: '部屋',
        text: ['', ''],
      },
      CATEGORY_UNSPECIFIED: {
        title: 'オーナー提供',
        text: ['Googleビジネスプロフィールのオーナーが追加した写真です。'],
      },
    },
    menus: {
      title: 'メニュー',
      text: [
        '飲食ビジネスの店舗の場合、Googleビジネスプロフィールにメニューの一覧を表示できます。',
        '来店を検討しているお客様に向けて、店舗で提供している商品や料理などのメニュー情報を設定してください。',
      ],
    },
    services: {
      title: 'サービス',
      text: [
        '飲食ビジネス以外の店舗の場合、Googleビジネスプロフィールにサービスの一覧を表示できます。',
        '来店を検討しているお客様に向けて、店舗で提供しているサービス情報を設定してください。',
      ],
    },
  },
  facebook: {
    pageName: {
      title: 'ページ名',
      text: [
        'Facebookページの名前です。',
        'Facebook APIの仕様上、カンリーでは編集できません。',
      ],
    },
    userName: {
      title: 'ユーザーネーム',
      text: [
        'FacebookページのURL末尾として使用される文字列です。',
        'Facebook APIの仕様上、カンリーでは編集できません。',
      ],
    },
    about: {
      title: 'ページ情報',
      text: [
        'この店舗の説明を記載してください。',
        '例）東京都内に○店舗を展開しているカンリー目黒店の公式Facebookページです。',
      ],
    },
    impressum: {
      title: '所属者情報',
      text: [
        'この店舗を運営している会社名を記載してください。',
        '例）株式会社カンリー',
      ],
    },
    category: {
      title: 'カテゴリ',
      text: ['この店舗のカテゴリ（業態）を選択してください。'],
    },
    tel: {
      title: '電話番号',
      text: [
        'この店舗の電話番号です。',
        '店舗マスタの「電話番号」の情報が反映されています。',
      ],
    },
    website: {
      title: 'ウェブサイト',
      text: [
        'この店舗の公式ウェブサイトURLを記載してください。',
        '例）https://jp.can-ly.com/',
      ],
    },
    emails: {
      title: 'メールアドレス',
      text: ['この店舗で使用しているメールアドレスを記載してください。'],
    },
    priceRange: {
      title: '価格帯',
      text: ['この店舗における価格帯を選択してください。'],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'この店舗の営業時間です。',
        '店舗マスタの「営業時間」が反映されています。',
      ],
    },
    description: {
      title: 'その他の情報',
      text: ['この店舗に関するその他の情報を記載してください。'],
    },
  },
  instagram: {
    userName: {
      title: 'ユーザーネーム',
      text: [
        '「@」以降の英数字で設定する固有IDです。',
        'Instagram APIの仕様上、カンリーでは編集できません。',
      ],
    },
  },
};
