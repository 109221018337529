import { BusinessHour } from '@/lib/api/schema';
import { addDays, parse } from 'date-fns';

export function validateHours(hours: BusinessHour[], isAcrossDays?: boolean) {
  if (!hours) return true;
  const invalid = hours.some(({ openTime, closeTime }) => {
    if (!openTime || !closeTime) return true;
    return isAcrossDays
      ? false
      : openTime !== [openTime, closeTime].sort().shift();
  });
  if (invalid) return false;
  if (isAcrossDays) {
    const parseHours = structuredClone(hours)
      .map((hour) => {
        const open = parse(
          hour.openTime as string,
          'HH:mm',
          new Date(),
        ).getTime();
        const close = parse(
          hour.closeTime as string,
          'HH:mm',
          new Date(),
        ).getTime();
        return {
          openTime: open,
          closeTime:
            open > close
              ? addDays(
                  parse(hour.closeTime as string, 'HH:mm', new Date()),
                  1,
                ).getTime()
              : close,
        };
      })
      .sort((a, b) => a.openTime - b.openTime);
    for (let i = 0; i < parseHours.length - 1; i++) {
      if (parseHours[i].closeTime > parseHours[i + 1].openTime) {
        return '時間の重複があります。';
      }
    }
    return true;
  }
  const sortedA: string[] = structuredClone(hours)
    .sort((a, b) => (a.openTime! > b.closeTime! ? 1 : -1))
    .flatMap((h) => [h.openTime!, h.closeTime!]);
  const sortedB = Array.from(new Set(sortedA)).sort();
  const strA = JSON.stringify(sortedA);
  const strB = JSON.stringify(sortedB);
  return strA === strB || '時間の重複があります。';
}
