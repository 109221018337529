import {
  BusinessHour,
  StoreBusinessDayBusinessHourType,
} from '@/lib/api/schema';

export const DayOfWeekLabels = [
  '日曜日',
  '月曜日',
  '火曜日',
  '水曜日',
  '木曜日',
  '金曜日',
  '土曜日',
];

export const StoreDayLabels = [
  '日曜日',
  '月曜日',
  '火曜日',
  '水曜日',
  '木曜日',
  '金曜日',
  '土曜日',
  '祝日',
  '祝前日',
];

export const OpenStatusLabels = ['開業', '休業', '閉業'];

export const DateTypeLabels: Record<'close' | 'open', string> = {
  close: '定休日',
  open: '営業日',
};

export const HourTypeLabels: Record<StoreBusinessDayBusinessHourType, string> =
  {
    close: '定休日',
    openHours: '時間指定',
    open24h: '24時間',
  };

export const HourLabels: Record<keyof BusinessHour, string> = {
  openTime: '開始',
  closeTime: '終了',
};

export const Labels = {
  button: {
    ok: 'OK',
    done: '決定',
    save: '保存',
    noSave: '保存しない',
    backToEdit: '編集に戻る',
    cancel: 'キャンセル',
    reset: 'すべての設定をリセット',
    addHours: '営業時間を追加',
    addDates: '特別営業時間を追加',
    bulkAddDates: '日付をまとめて追加',
  },
  dateType: DateTypeLabels,
  hourType: HourTypeLabels,
  hour: HourLabels,
};

export const BookingLabels = {
  APPOINTMENT: '予約',
  ONLINE_APPOINTMENT: 'オンライン診察',
  DINING_RESERVATION: 'レストラン予約',
  FOOD_ORDERING: 'デリバリー・テイクアウト',
  FOOD_DELIVERY: 'デリバリー',
  FOOD_TAKEOUT: 'テイクアウト',
  SHOP_ONLINE: 'オンラインショップ',
  PLACE_ACTION_TYPE_UNSPECIFIED: '指定なし',
};

export const MoreHourLabels = {
  online: 'オンラインサービスの提供時間',
  takeAway: 'テイクアウト',
  dinner: 'ディナー',
  driveThrough: 'ドライブスルー',
  happyHour: 'ハッピーアワー',
  branch: 'ブランチ',
  lunch: 'ランチ',
  collect: '受け取り',
  delivery: '宅配',
  breakfast: '朝食',
  order: '注文可能時間',
  silverOnly: '高齢者限定時間帯',
} as const;

// export const AssetLabels: string[] = [
//   'オーナー提供',
//   'ユーザー提供',
//   'カバー',
//   'ロゴ',
//   '外観',
//   '店内',
//   '食品と飲料',
//   '商品',
//   'メニュー',
//   'チーム',
//   '職場',
// ] as const;

export const AssetLabels = {
  owner: 'オーナー提供',
  user: 'ユーザー提供',
  cover: 'カバー',
  logo: 'ロゴ',
  outer: '外観',
  inner: '店内',
  items: '食品と飲料',
  products: '商品',
  menues: 'メニュー',
  people: 'チーム',
  'work-place': '職場',
} as const;

export const MediaCategories = [
  { label: 'オーナー提供', categories: ['ADDITIONAL', 'CATEGORY_UNSPECIFIED'] },
  { label: 'ユーザー提供', categories: ['CUSTOMERS'] },
  { label: 'カバー', categories: ['COVER'] },
  { label: 'ロゴ', categories: ['LOGO'] },
  { label: 'プロフィール', categories: ['PROFILE'] },
  { label: '外観', categories: ['EXTERIOR'] },
  { label: '店内', categories: ['INTERIOR'] },
  { label: '食品と飲料', categories: ['FOOD_AND_DRINK'] },
  { label: '商品', categories: ['PRODUCT'] },
  { label: 'メニュー', categories: ['MENU'] },
  { label: 'チーム', categories: ['TEAMS'] },
  { label: '職場', categories: ['AT_WORK'] },
  { label: '共用エリア', categories: ['COMMON_AREA'] },
  { label: '部屋', categories: ['ROOMS'] },
];

export const YahooMediaCategories = [
  { label: 'カバー', categories: ['COVER'] },
  { label: '製品・サービス', categories: ['SECONDARY'] },
  { label: '内観', categories: ['INSIDE'] },
  { label: '外観', categories: ['OUTSIDE'] },
  { label: 'その他', categories: ['ETC'] },
];

export const dataLabel = {
  facebook: {
    priceRanges: [
      { value: 1, title: '￥', description: '安い' },
      { value: 2, title: '￥￥', description: '中程度' },
      { value: 3, title: '￥￥￥', description: '高い' },
      {
        value: 4,
        title: '￥￥￥￥',
        description: 'かなり高い',
      },
      {
        value: 99,
        title: '未定',
        description: '価格帯を指定しない場合に選択してください',
      },
      {
        value: 0,
        title: '該当なし',
        description: '価格帯は利用できません',
      },
    ],
    businessHoursTypes: [
      { value: 0, label: '特定の営業時間に営業' },
      { value: 1, label: '24時間営業' },
      { value: 2, label: '営業時間記載なし' },
      { value: 3, label: '閉店・閉鎖' },
    ],
  },
};
